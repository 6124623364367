import * as React from "react";

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, GatsbyLinkProps, navigate, useStaticQuery, graphql } from 'gatsby'
import { styled, withStyles } from '@material-ui/styles'
import { path } from 'ramda';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Image from 'material-ui-image';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Wrapper } from '../../components/wrapper';
import { HR } from "../../components/hr";
import { Theme } from "@material-ui/core";

const LinkComponent = React.forwardRef<HTMLAnchorElement, Omit<GatsbyLinkProps<{}>, 'ref'>>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const BACKGROUND_COLOR = '#F5F7FA';

/*

0px 2px 8px 0px rgba(23, 32, 38, 0.08), 0px 2px 4px 0px rgba(23, 32, 38, 0.06)

*/

const ServiceName = withStyles((theme: Theme) => ({
  root: {
    fontSize: '20px',
    color: '#071D3B',
    letterSpacing: 0,
    // textAlign: 'center'
  }
}))(Typography);

const ServiceDescription = withStyles((theme: Theme) => ({
  root: {
    fontFamily: 'Open Sans',
    opacity: 0.5,
    color: '#071D3B',
    letterSpacing: 0,
  }
}))(Typography)

const IconWrapper = withStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '50%'
    },
    
  }
}))(Box)


const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
})

const ServiceCard = withStyles((theme: Theme) => ({
  root: {
    marginTop: '15px',
    borderTopLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    padding: theme.spacing(2)
  }
}))(Paper)

export default () => {
  const data = useStaticQuery(graphql`
  {
    allContentfulService (limit: 6) {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 320)
              rawMarkdownBody
            }
          }
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    allContentfulLayoutCopy(
      filter: { node_locale: { eq: "en" }, title: { eq: "Services > Header" } } 
      limit: 1
    ) {
      edges {
        node {
          copy {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
  `)
  const services = data.allContentfulService.edges.map((service) => {
    const svgPath = path(['node', 'icon', 'localFile', 'publicURL'], service);
    const serviceName = path(['node', 'name'], service);
    const serviceDescription = path(['node', 'description', 'childMarkdownRemark', 'rawMarkdownBody'], service);
    return (
      <ServiceCard>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12} sm={2}>
            <IconWrapper>
              <Image src={svgPath} />
            </IconWrapper>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <ServiceName>{serviceName}</ServiceName>
            <ServiceDescription>{serviceDescription}</ServiceDescription>
          </Grid>
        </Grid>
      </ServiceCard>
    )
  })
  const heroBody = path(['allContentfulLayoutCopy', 'edges', 0, 'node', 'copy', 'childMarkdownRemark', 'rawMarkdownBody'], data)
  return (
    <Layout>
      <Container maxWidth='md'>

      <SEO title="Dental Services" />

      <Box my={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={LinkComponent} color="inherit" to="/en">
            Home
          </Link>
          <Typography color="textPrimary">Services</Typography>
        </Breadcrumbs>
      </Box>

      <HR />

      <Box my={3}>
      
        <Typography variant='h4' component='h1'>
          Our Services
        </Typography>

        <Box my={4}>
          <Typography>
            {heroBody}
          </Typography>
        </Box>

      </Box>
      </Container>
     
      <Wrapper>
        <Container maxWidth='md'>
          <Box py={4}>
            {services}
          </Box>
        </Container>
      </Wrapper>

    </Layout>
  );
}
